<template>
  <div class="success-create">
    <!-- 会议已创建成功 -->
    <div class="create-header dfr flex-center">
      <div class="succeed-box">
        <img src="../assets/images/succeed.png" alt="" class="w100 h100" />
      </div>
      <div class="success-test fs36 fb fctheme ml16">会议已创建成功</div>
    </div>

    <div v-if="meetingInfo" @click="goDetail(meetingInfo.id)" class="caseOwner">
      <!-- 案主信息 -->
      <div class="dfr pt36 pl36 pr36 pb36">
        <!-- 头像 -->
        <div class="item-portrait-box">
          <!-- 用户头像 -->
          <img
            v-if="meetingInfo.user.avatar"
            :src="meetingInfo.user.avatar"
            alt=""
            class="w100 h100 object-cover"
          />
          <!-- 默认头像 -->
          <img
            v-else
            src="../assets/images/default-portrait.png"
            alt=""
            class="w100 h100"
          />
          <!-- 徽章 -->
          <div v-if="meetingInfo.user.is_major_auth" class="item-badge-box">
            <img
              src="../assets/images/home-emblem.png"
              alt=""
              class="w100 h100"
            />
          </div>
        </div>
        <!-- 信息 -->
        <div class="item-message">
          <div class="dfc">
            <div class="dfr cross-center">
              <!-- 姓名 -->
              <div class="fs32 fw600 fc333">
                {{ meetingInfo.user.name }}
              </div>
              <!-- 职位 -->
              <div class="fs24 fw400 fc999 ml16">
                {{ meetingInfo.user.job }}
              </div>
              <div class="owner-tag ml26">
                <name-plate nameplate="案主"></name-plate>
              </div>
            </div>
            <!-- 公司 -->
            <div class="mt8 dfr cross-center flex-wrap">
              <div class="fs24 fw400 fc666">
                {{ meetingInfo.user.company }}
              </div>
            </div>
            <!-- 公司行业 公司规模 -->
            <div
              v-if="meetingInfo.user.industry && meetingInfo.user.scale"
              class="mt4 dfr cross-center flex-wrap"
            >
              <div class="fs24 fw400 fcb2">
                {{ meetingInfo.user.industry.name }}
                {{ meetingInfo.user.scale.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 主持人信息 -->
      <div class="host-wrap dfr cross-center main-between fs24">
        <div class="host-info-left dfr cross-center">
          <div class="fc999">主持人</div>
          <div class="fc666 ml16">{{ meetingInfo.host_user.name }}</div>
          <div class="owner-tag ml46">
            <name-plate
              :nameplate="meetingInfo.host_user.host_user_info.level.name"
            ></name-plate>
          </div>
        </div>
        <div class="host-info-right fctheme fs26">待付款</div>
      </div>
    </div>

    <!-- 分享给案主付款  返回首页 -->
    <div class="btn-box dfr flex-center mt80">
      <icy-button @click.native="showShare" width="2.76rem" height=".8rem"
        >分享给案主付款</icy-button
      >
      <icy-button
        background="#fff"
        border="1px solid #CB9025"
        color="#CB9025"
        class="ml22"
        width="2.76rem"
        height=".8rem"
        @click.native="goHome"
        >返回首页</icy-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      meetingId: "",
      meetingInfo: null,
    };
  },
  created() {
    this.$title("会议已创建成功");
    this.meetingId = parseInt(this.$route.query.meetingId) + "" || "";
    this.initPage();
  },
  mounted() {},
  methods: {
    async initPage() {
      await this.initMeetingInfo();
      this.initShare();
    },
    async initMeetingInfo() {
      let _meetingInfo = {};
      const resp = await this.$API
        .get("meetingDetail", { id: this.meetingId })
        .then((resp) => resp);
      if (resp.code == 1000) {
        _meetingInfo = resp.data;
      }
      this.meetingInfo = _meetingInfo;
    },
    initShare() {
      const shareLink =
        location.origin +
        location.pathname +
        `#/meetingPaid?meetingId=${this.meetingInfo.id}`;
      this.shareLink = shareLink;
      let field = "";
      if (this.meetingInfo.field) {
        field = this.meetingInfo.field.name;
      }
      let info = `我的困惑是如何「${this.meetingInfo.questions[0]}」这对「${this.meetingInfo.questions[1]}」很重要，我针对这个问题已做了「${this.meetingInfo.questions[2]}」，我希望帮我「 ${this.meetingInfo.questions[3]}」`;

      const shareInfo = {
        title: `${this.meetingInfo.host_user.name}代你创建的${field}私董会待你支付`,
        desc: `${info}`,
        // 朋友圈标题描述
        longTitle: `${info}`,
        link: shareLink,
        imgUrl: [location.origin + "/share-icon1.png"],
      };

      this.$bus.$emit("hasShare", shareInfo);
    },
    goDetail() {},
    goHome() {
      this.$go("home");
    },
    showShare() {
      this.$bus.$emit("showShareGuide", true);
      this.$bus.$emit("shareGuideHref", this.shareLink);
    },
  },
  destroyed() {},
};
</script>
<style lang="less" scoped>
.success-create {
  padding-top: 0.6rem;
  .succeed-box {
    width: 1.6rem;
    height: 1.6rem;
  }
  .caseOwner {
    width: 6.78rem;
    min-height: 3.02rem;
    box-shadow: 0px 0.04rem 0.4rem 0px rgba(0, 0, 0, 0.05);
    border-radius: 0.12rem;
    margin: 0.4rem auto 0;
    .item-inner-box {
      width: 6.94rem;
      background: #ffffff;
      margin-top: 0.28rem;
      border-radius: 0.12rem;
      box-sizing: border-box;
      overflow: hidden;
    }
    .item-portrait-box {
      width: 0.96rem;
      height: 0.96rem;
      position: relative;
      img {
        border-radius: 50%;
        object-fit: cover;
      }
      .item-badge-box {
        width: 0.28rem;
        height: 0.28rem;
        position: absolute;
        right: 0;
        bottom: 0;
        img {
          position: absolute;
          object-fit: cover;
        }
      }
    }
    .item-message {
      margin-left: 0.2rem;
    }
    .owner-tag {
      transform: scale(1.2);
      transform-origin: right center;
    }
    .item-delete {
      width: 0.28rem;
      height: 0.28rem;
      margin-right: 0.36rem;
      img {
        object-fit: cover;
      }
    }

    .field-item-box {
      margin: 0.24rem 0.36rem 0.36rem 0.36rem;
      .field-item {
        background: #f6f6f6;
        padding: 0.08rem 0.16rem;
        margin: 0.12rem 0.12rem 0 0;
      }
    }
  }

  .host-wrap {
    border-top: 0.01rem solid #f7f7f7;
    padding: 0.36rem;
  }
}
</style>
